Foundation.Drilldown.defaults.backButton = '<li class="js-drilldown-back"><a tabindex="0">Zpět</a></li>';
Foundation.Drilldown.defaults.autoHeight = true;
Foundation.Drilldown.defaults.animateHeight = true;
Foundation.Equalizer.defaults.equalizeOnStack = false;

$(document).foundation();

$(document).ready(function () {
});

/*let vide_options = {
 poster: '/files/images/header_Bg',
 posterType: 'jpg'
 };

 if (Foundation.MediaQuery.atLeast('medium')) {
 vide_options.mp4 = '/files/videos/header';
 }

 $('#background_video').vide(
 vide_options
 );*/

AOS.init();

lightbox.option(
    {
        'albumLabel': '%1 / %2',
    }
);


/*let $youtubeSlider = $('#youtube_slider');
 $youtubeSlider.slick(
 {
 lazyLoad: 'ondemand',
 dots: true,
 // 		arrows       : false,
 autoplay: true,
 autoplaySpeed: 5000
 }
 );


 $youtubeSlider.find('a')
 .on('mousedown', function (e) {
 $(this).data('moving', false);
 })
 .on('mousemove', function (e) {
 $(this).data('moving', true);
 })
 .on('mouseup', function (e) {
 if (!$(this).data('moving')) {
 let $that = $(this);
 e.preventDefault();
 $.post($that.data('href'), null, function (data) {
 $('#video_modal').html(data).foundation('open');
 });
 }
 });*/

$('[data-ajax-load]').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    let href = $this.attr('href');

    $.post(href, null, function (data) {
        $('#universalModal').html(data).foundation('open');
    });
});

$('.formular-view-3 .wrap')
    .each(function () {
        let $that = $(this);
        let label = $that.children('.formInput-Label').remove();
        let placeholder = label.text().trim().toUpperCase();
        $that.find('input, textarea').attr('placeholder', placeholder).attr('required', 'required');
    });

$('#home_header_wrap').children().unwrap();


const isInView = (elem) => {
    let elementPosTop = elem.offset().top;
    let scrollBottom = $(window).scrollTop() + $(window).outerHeight();
    return elementPosTop < scrollBottom;
};

($ => {
    const $window = $(window);

    const $menu19 = $('.menu_19');

    const $links = $menu19.find('.links');
    const $backToTop = $('#backToTop');

    $backToTop.on('click', e => {
        $('html, body').animate({scrollTop: 0}, '500', 'swing');
    });
    $window.on('scroll', () => {
        if (isInView($menu19)) {
            $links.removeClass('unplugged');
        } else {
            $links.addClass('unplugged');
        }


        if ($(window).scrollTop() > 1000) {
            $backToTop.removeClass('hiding');
        } else {
            $backToTop.addClass('hiding');
        }
    });

    // $('#menu_placeholder').append($('#responsiveMenuWrap'));
})(jQuery);